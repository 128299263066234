<template>
  <div v-if="recommendedList?.length">
    <div class="titleRow inline-block">Рекомендуем посмотреть</div>
    <PosterSwiper
      :posters="recommendedList"
      local-storage-key="recommendedList"
    />
  </div>
</template>

<script setup>
import PosterSwiper from '@/components/ui/Swipers/PosterSwiper.vue';

const recommendedStore = useRecommendedListStore();
const { recommendedList } = storeToRefs(recommendedStore);
recommendedStore.fetchRecommended();
</script>
